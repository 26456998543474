.privacy{
  &__content{
    position: relative;
    z-index: 10;
    padding: 0 80px;
    margin-top: 198px;
    @include mediaM{
      padding: 0 4%;
      margin-top: 20%;
    }
  }
  &__title{
    font-size: 2rem;
    @extend .noto-serif;
    font-weight: 700;
    @include mediaM{
      font-size: 1.5rem;
    }
    @include mediaXS{
      font-size: 1.25rem;
    }
  }
  &__inner{
    @extend .noto-serif;
    margin-top: 154px;
    @include mediaM{
      margin-top: 15%;
    }
    &__title{
      font-size: 1rem;
      line-height: 21px;
      @include mediaXS{
        font-size: 0.875rem;
        line-height: 19px;
      }
    }
    dt{
      margin-top: 21px;
      font-size: 1rem;
      line-height: 21px;
      @include mediaXS{
        margin-top: 19px;
        font-size: 0.875rem;
        line-height: 19px;
      }
    }
    dd{
      font-size: 1rem;
      line-height: 21px;
      @include mediaXS{
        font-size: 0.875rem;
        line-height: 19px;
      }
      ul{
        li{
          display: flex;
          align-items: flex-start;
          font-size: 1rem;
          line-height: 21px;
          @include mediaXS{
            font-size: 0.875rem;
            line-height: 19px;
          }
          span{
            display: block;
          }
          .circle{
            width: 3px;
            height: 3px;
            border-radius: 50%;
            background: $black;
            flex-shrink: 0;
            margin: 10px 4px 0;
            @include mediaXS{
              margin: 8px 4px 0;
            }
          }
        }
      }
    }
  }
}