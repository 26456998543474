.shop{
  &__title{
    font-size: 2rem;
    @include mediaM{
      font-size: 1.5rem;
    }
    @include mediaXS{
      font-size: 1.25rem;
    }
    &.space{
      margin-top: 150px;
      margin-left: 80px;
      @include mediaM{
        margin-left: 4%;
        margin-top: 15%;
      }
    }
  }
  &__detail{
    font-size: 1.125rem;
    line-height: 26px;
    margin-top: 10px;
    @include mediaM{
      font-size: 1rem;
      line-height: 24px;
    }
    @include mediaXS{
      font-size: 0.875rem;
      line-height: 22px;
    }
  }
  &__content{
    position: relative;
    z-index: 10;
    margin-left: 80px;
    @include mediaM{
      margin-left: 4%;
    }
    &.counter{
      margin-top: 300px;
      @include mediaM{
        margin-top: 30%;
      }
    }
    &.rise{
      margin-top: 192px;
      @include mediaM{
        margin-top: 18%;
      }
    }
    &.private{
      margin-top: 332px;
      @include mediaM{
        margin-top: 33%;
      }
    }
    &.hall{
      margin-top: 192px;
      @include mediaM{
        margin-top: 18%;
      }
    }
  }
  &__image{
    margin-top: 60px;
    width: 65.3%;
    @include mediaM{
      margin-top: 32px;
      width: 87%;
    }
    img{
      display: block;
      width: 100%;
    }
  }
  &__flex{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 60px;
    @include mediaM{
      margin-top: 32px;
    }
    @include mediaS{
      flex-direction: column;
    }
    &__left{
      width: 46.8%;
      @include mediaS{
        width: 85%;
      }
    }
    &__right{
      margin-top: 180px;
      width: 47.5%;
      @include mediaM{
        margin-top: 16%;
      }
      @include mediaS{
        margin-top: 24px;
        width: 85%;
        margin-left: auto;
      }
    }
    img{
      display: block;
      width: 100%;
    }
  }
}