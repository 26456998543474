.news{
  background-image: url('../img/top/news-back.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 100%;
  @include mediaXS{
    background-image: url('../img/top/news-back-sp.png');
  }
  &__inner{
    @extend .content-extend;
    padding-top: 260px;
    padding-bottom: 280px;
    @include mediaM{
      padding-top: 30%;
      padding-bottom: 32%;
    }
  }
  &__title{
    font-size: 2rem;
    @include mediaS{
      font-size: 1.375rem;
    }
    @include mediaXS{
      margin-top: 64px;
    }
  }
  &__list{
    margin-top: 48px;
    max-width: 570px;
    width: 100%;
    position: relative;
    z-index: 3;
    @include mediaXS{
      margin-top: 40px;
    }
    &__item{
      padding-bottom: 8px;
      border-bottom:1px solid $black;
      &:nth-child(n + 2){
        margin-top: 60px;
        @include mediaXS{
          margin-top: 24px;
        }
      }
      &__link{
        display: flex;
        align-items: flex-start;
        padding: 0 6px;
        color: $black;
        @include mediaXS{
          flex-direction: column;
        }
        p{
          font-size: 0.875rem;
          line-height: 20px;
          // @include mediaXS{
          //   font-size: 0.75rem;
          //   line-height: 18px;
          // }
        }
        &__data{
          width: 110px;
          flex-shrink: 0;
          @include mediaXS{
            width: 100%;
          }
        }
        &__name{
          width: 100%;
          @include mediaXS{
            margin-top: 6px;
          }
        }
      }
    }
  }
}
.news-detail{
  padding: 150px 80px 200px;
  background-image: url('../img/top/news-back.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 100%;
  @include mediaM{
    padding: 15% 4% 20%;
  }
  @include mediaXS{
    background-image: url('../img/top/news-back-sp.png');
  }
  &__inner{
    // max-width: 780px;
    width: 80%;
    @include mediaM{
      width: 100%;
    }
    &__title{
      display: flex;
      align-items: flex-start;
      padding-bottom: 16px;
      border-bottom: solid 1px $black;
      @include mediaS{
        flex-direction: column;
      }
      p{
        font-size: 1.25rem;
        @include mediaM{
          font-size: 1.125rem;
        }
        @include mediaXS{
          font-size: 1rem;
        }
      }
      &__data{
        width: 140px;
        flex-shrink: 0;
        @extend .noto-sans;
        @include mediaS{
          width: 100%;
        }
      }
      &__name{
        width: 100%;
        font-weight: 400;
        font-size: 1.25rem;
        @include mediaM{
          font-size: 1.125rem;
        }
        @include mediaS{
          margin-top: 8px;
        }
        @include mediaXS{
          font-size: 1rem;
        }
      }
    }
    &__image{
      width: 88%;
      margin-top: 30px;
      img{
        display: block;
        width: 100%;
      }
    }
    &__description{
      font-size: 1rem;
      font-family: $default;
      margin-top: 30px;
      @include mediaM{
        font-size: 0.875rem;
      }
      div, p{
        font-size: 1rem;
        font-family: $default;
        @include mediaM{
          font-size: 0.875rem;
      }
      }
    }
  }
}