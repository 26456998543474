/*共通部分*/
html {
  font-size: 100%;
  letter-spacing: 0.22em;
  visibility: hidden;
  opacity: 0;
  
}

// Webフォントの読み込みを終えたら表示
html.wf-active{
  visibility: visible;
  opacity: 1;
}
.grecaptcha-badge{
  visibility: hidden;
}
body {
  font-family: $default;
  line-height: 1.5;
  color: $black;
  font-weight: 400;
  letter-spacing: 0;
  position: relative;
  overflow: hidden;
  @include mediaS{
    font-size: 0.875rem;
  }
  @include mediaXS{
    font-size: 0.75rem;
  }
}
body.fixed{
  width: 100%;
  position: fixed;
  overflow: hidden;
}
.max-extend {
  max-width: 1920px;
  margin: 0 auto;
}
.max-extend-inner {
  max-width: 1366px;
  width: 100%;
  margin: 0 auto;
  padding: 0 4%;
}
.content-extend{
  max-width: 1366px;
  padding-left: 80px;
  padding-right: 80px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  @include mediaM{
    padding-left: 4%;
    padding-right: 4%;
  }
}
//フォント
.noto-serif{
  font-family: $notoSerif;
}
.noto-sans{
  font-family: $notoSans;
}
//コンテンツに余白をつける。
.wrapper {
  padding: 0 4%;
}
.content-wrapper {
  padding: 0 5%;
}
h1,h2,h3, h4, h5, h6{
  font-size: 2rem;
  font-weight: 400;
  @include mediaS {
    font-size: 1.5rem;
  }
  @include mediaXS {
    font-size: 1.25rem;
  }
}
b {
  font-weight: 700;
}
p {
  line-height: 24px;
  font-size: 1rem;
  @include mediaS {
    font-size: .875rem;
    line-height: 21px;
  }
  @include mediaXS {
    font-size: .75rem;
  }
}
.flex-reverse{
  flex-direction: row-reverse;
}
a {
  text-decoration: none;
}

img {
  max-width:100%;
}
button{
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
}
//spでbr
.sp-br {
  display: none;
  @include mediaS {
    display: block;
  }
}
// show
.pc-show{
  display: block;
  @include mediaS{
    display: none;
  }
}
.m-show{
  display: none;
  @include mediaM{
    display: block;
  }
  @include mediaS{
    display: none;
  }
}
.l-show{
  display: none;
  @include mediaL{
    display: block;
  }
}
.sp-show{
  display: none;
  @include mediaS{
    display: block;
  }
}
.sp-xs-none{
  display: none;
  @include mediaS{
    display: block;
  }
  @include mediaXS{
    display: none;
  }
}
.sp-xs-show{
  display: none;
  @include mediaXS{
    display: block;
  }
}
.xs-show{
  display: none;
  @include mediaXS{
    display: block;
  }
}
.m-none{
  display: block;
  @include mediaM{
    display: none;
  }
}
.xs-none{
  display: block;
  @include mediaXS{
    display: none;
  }
}
ul {
  list-style: none;
}
select {
  appearance: revert;
}
table {
  border-collapse: collapse;
  width: 100%;
  text-align: left;
  th, td {
    @include mediaXS {
      font-size: .875rem;
    }
  }
}
ol {
  padding-left: 20px;
}
ul {
  list-style: none;
}

.back-inherit {
  background-color: inherit!important;
}

.text-align-center{
  text-align: center;
}
.mr-right{
  margin-right: 4%;
  
}

.flex{
  display: flex;
  @include mediaS{
    display: block;
  }
}
.flex-warp{
  flex-wrap: wrap;
}
.flex-as{
  display: flex;
}
.justify{
  &-between{
    justify-content: space-between;
  }
  &-end{
    justify-content: end;
    
  }
  &-around{
    justify-content: space-around;
  }
  &-center{
    justify-content: center;
  }
}
.align{
  &-center{
    align-items: center;
  }
}
.no-warp{
  white-space: nowrap;
}
.pointer {
  cursor: pointer;
}

/* フォントカラー */
.fc{
  &-white{
    color: $white;
  }
}

/* bottom 三角 */
.triangle{
  &-bottom{
    margin: 48px auto;
    width: 0;
    height: 0;
    border-left: 250px solid transparent;
    border-right: 250px solid transparent;
    border-top: 64px solid $black;
    @include mediaXS{
      border-left: 150px solid transparent;
      border-right: 150px solid transparent;
      border-top: 50px solid $black;
    }
  }
}
.vertical-font{
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
}
.position-right{
  text-align: right;
}
.position-left{
  text-align: left;
}
.regular{
  font-weight: 400;
}
.medium{
  font-weight: 500;
}
.semi-bold{
  font-weight: 600;
}
.bold{
  font-weight: 700;
}
.d-inline{
  display: inline-block;
}
.arrow-right{
  &:after{
    content: '';
    position: absolute;
    right: 32px;
    top: 16px;
    width: 7px;
    height: 7px;
    border: 1px solid;
    border-color: $white $white transparent transparent;
    transform: rotate(45deg);
  }
}