.bg-para{
  position: relative;
  z-index: 2;
  &.top{
    height: 1400px;
    width: 100%;
    margin-top: -820px;
    @include mediaLL{
      margin-top: -55%;
    }
    @include mediaM{
      height: 1200px;
    }
    @include mediaS{
      height: 1000px;
    }
    @include mediaXS{
      height: auto;
      padding-top: 200%;
    }
    @include mediaPHONE{
      padding-top: 230%;
    }
    &:after{
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background: rgba($color: $white, $alpha: .52);
    }
    .text{
      position: absolute;
      z-index: 2;
      top: 57%;
      right: 10%;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      font-size: 1.125rem;
      color: $black;
      line-height: 26px;
      @include mediaGrid{
        top: 50%;
        right: 6%;
      }
      @include mediaS{
        font-size: 0.875rem;
        top: 45%;
      }
      @include mediaPHONE{
        line-height: 22px;
        top: 37%;
      }
    }
  }
  .img-parallax{
    clip: rect(0, auto, auto, 0);
    margin-bottom: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: -1;
    img{
      width: 100%;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      padding: 0;
      margin: 0;
      object-fit: cover;
      z-index: 0;
      transform: translateZ(0);
    }
  }
}
.common-fv{
  width: 100%;
  position: relative;
  height: 900px;
    @include mediaLL{
      height: 770px;
    }
    @include mediaML{
      height: 700px;
    }
    @include mediaM{
      height: 600px;
    }
    @include mediaS{
      height: 500px;
    }
    @include mediaXS{
      height: 460px;
    }
  img{
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &__title{
    position: absolute;
    right: 40px;
    bottom: 118px;
    font-size: 5rem;
    color: $white;
    @include mediaL{
      right: 24px;
      font-size: 3.5rem;
    }
    @include mediaM{
      font-size: 3.5rem;
      right: 4%;
    }
    @include mediaS{
      font-size: 3rem;
    }
    @include mediaXS{
      bottom: 88px;
      font-size: 2.5rem;
    }
    &.privacy-title{
      width: 480px;
      @include mediaL{
        width: 336px;
      }
      @include mediaS{
        width: 288px;
      }
      @include mediaXS{
        width: 240px;
      }
    }
  }
}
.common-btn-flex{
  display: flex;
  justify-content: space-between;
  max-width: 780px;
  margin-top: 200px;
  margin-left: auto;
  margin-right: auto;
  @include mediaM{
    flex-direction: column;
    margin-top: 20%;
  }
  .left-btn{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 330px;
    overflow: hidden;
    @include mediaXS{
      max-width: 240px;
      width: 85%;
    }
    img{
      transition: .3s;
      width: 100%;
      display: block;
    }
  }
  .right-btn{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 330px;
    overflow: hidden;
    @include mediaM{
      margin-top: 32px;
    }
    @include mediaXS{
      max-width: 240px;
      width: 85%;
    }
    img{
      transition: .3s;
      width: 100%;
      display: block;
    }
  }
  &:hover{
    img{
      transform: scale(1.05);
    }
  }
}
.common-nav{
  position: relative;
  z-index: 9;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: 160px;
  @include mediaM{
    margin-top: 100px;
  }
  @include mediaM{
    margin-top: 80px;
    display: grid;
    align-items: inherit;
    justify-content: inherit;
    grid-template-columns: repeat(2 , 1fr);
    column-gap: 15px;
    row-gap: 25px;
  }
  @include mediaXS{
    margin-top: 64px;
    
  }
  &__link{
    display: block;
    position: relative;
    &:nth-child(even){
      margin-top: 80px;
      @include mediaM{
        margin-top: 0;
      }
    }
    &:nth-child(n + 2){
      margin-left: 80px;
      @include mediaML{
        margin-left: 40px;
      }
      @include mediaM{
        margin-left: 0;
      }
    }
    &:nth-child(odd){
      @include mediaM{
        margin-left: auto;
      }
    }
    padding: 12px 8px 20px;
    background: $black;
    color: $white;
    text-align: center;
    font-size: 1.375rem;
    width: 200px;
    @include mediaM{
      font-size: 1.125rem;
      width: 150px;
    }
    @include mediaS{
      padding: 8px 8px 16px;
      font-size: 1rem;
    }
    &:after{
      content: '';
      position: absolute;
      bottom: 12px;
      left: 50%;
      transform: translateX(-50%);
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      background-image: url('../img/menu/bottom-arrow.svg');
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      width: 12px;
      height: 6px;
      @include mediaS{
        bottom: 6px;
        width: 10px;
        height: 6px;
      }
    }
  }
  &.shop{
    margin-top: 56px;
    @include mediaM{
      margin-top: 32px;
    }
    .common-nav__link{
      width: 250px;
      @include mediaS{
        width: 200px;
      }
      @include mediaXS{
        width: 150px;
      }
      &:nth-child(n + 2){
        margin-left: 80px;
        @include mediaL{
          margin-left: 40px;
        }
        @include mediaGrid{
          margin-left: 20px;
        }
        @include mediaM{
          margin-left: 0;
        }
      }
      &:nth-child(odd){
        @include mediaM{
          margin-left: auto;
        }
      }
    }
  }
}
.common-access{
  margin-top: 300px;
  @include mediaM{
    margin-top: 30%;
  }
  &__inner{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0 80px;
    @include mediaM{
      padding: 0 4%;
      flex-direction: column;
    }
    &__map{
      width: calc(100% - 536px);
      @include mediaML{
        width: calc(100% - 490px);
      }
      @include mediaM{
        width: 100%;
      }
      iframe{
        width: 100%;
        height: 550px;
        @include mediaM{
          height: 400px;
        }
        @include mediaS{
          height: 350px;
        }
        @include mediaXS{
          height: 300px;
        }
      }
    }
    &__content{
      width: 460px;
      flex-shrink: 0;
      @include mediaM{
        width: 100%;
        margin-top: 24px;
      }
      &__title{
        font-size: 1.625rem;
        @include mediaS{
          font-size: 1.375rem;
        }
      }
      dl{
        margin-top: 24px;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        dt{
          width: 150px;
          flex-shrink: 0;
          font-size: 1rem;
          line-height: 24px;
          &.space{
            margin-top: 16px;
            @include mediaS{
              margin-top: 24px;
            }
          }
          @include mediaS{
            font-size: 0.875rem;
            line-height: 20px;
            width: 117px;
            padding-left: 12px;
          }
        }
        dd{
          width: calc(100% - 150px);
          font-size: 1rem;
          line-height: 24px;
          @include mediaS{
            line-height: 20px;
            font-size: 0.875rem;
            width: calc(100% - 117px);
          }
          &.space{
            margin-top: 16px;
            @include mediaS{
              margin-top: 24px;
            }
          }
        }
      }
    }
  }
}