/*----scrollFadein----*/
.scroll-fadein{
  opacity: 0;
  &.show{
    animation: up-fadein 1s ease-out forwards;
  }
}
@keyframes up-fadein {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/*時間差でフェイドイン*/
@for $n from 1 through 10 {
  .scroll-fadein-delay#{$n}{
    opacity: 0;
    &.show{
      animation: up-fadein 1s ease-out forwards;

      $delay:0;
      @for $i from 1 through 20 {
        &:nth-of-type(#{$i}) {
          animation-delay: $delay + s;
        }
        $delay: $delay + .5;
      }
    }
  }
}

/*右から左にフェイドイン*/
.scroll-fadein-right{
  opacity: 0;
  &.show{
    opacity: 1;
    animation: right-fadein 1s ease-out;
  }
}
@keyframes right-fadein {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/*左から右にフェイドイン*/
.scroll-fadein-left{
  opacity: 0;
  &.show{
    opacity: 1;
    animation: left-fadein 1s ease-out;
  }
}
@keyframes left-fadein {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
// 左から文字が表示されるフェードイン
.side-fadein {
  overflow: hidden;
  transform: translate(-100%, 0);
  transition: transform cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s;
}
.side-fadein span {
  display: block;
  transform: translate(100%, 0);
  transition: transform cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s;
}
.side-fadein.show,
.side-fadein.show span {
  transform: translate(0, 0);
}

// 一文字ずつ出てくるアニメーション
.js-one-text-up-fadein {
  opacity: 0;
}

.sp-js-span-wrap-text, .js-span-wrap-text, .js-span-wrap-bottom-text, .js-one-text-up-fadein {
  span {
    opacity: 0;
    display: inline-block;
    @for $i from 1 through 43 {
      $t: $i+6;
      &:nth-child(#{$i}) {
        animation: oneFade 1s ease #{$t * 0.1}s forwards;
      }
    }
  }

}

@keyframes oneFade {
  0% {
    opacity: 0;
    transform: translateY(20px);

  }
  100% {
    opacity: 1;
    transform: translateY(0);

  }
}
// ========= 1文字ずつ出現させるためのCSS ===============

.eachTextAnime span{opacity: 0;}
.eachTextAnime.appeartext span{ animation:text_anime_on 1s ease-out forwards; }
@keyframes text_anime_on {
  0% {opacity:0;}
  100% {opacity:1;}
}
