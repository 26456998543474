.footer{
  background: $black;
  padding: 80px 4% 32px;
  margin-top: 300px;
  @include mediaM{
    margin-top: 120px;
    padding: 80px 4% 8px;
  }
  &__small{
    display: block;
    text-align: center;
    color: $white;
    font-size: 0.875rem;
    @extend .noto-sans;
    @include mediaS{
      font-size: 0.75rem;
    }
  }
}