@charset "UTF-8";
/*# sourceMappingURL=../css/style.css.map */

/*--基本スタイル--*/
@import "prepends";

/*--mixin--*/
//共通CSS
@import "mixins/variables";
//ヘッダー
@import "mixins/global-header";
//フッター
@import "mixins/footer";
//pageTop
@import "mixins/page-top";
//共通コンテンツ
@import "mixins/common";
//共通コンテンツ
@import "mixins/btn";
// フェイドイン
@import "mixins/scroll-fadein";
// ページネーション 
@import "mixins/pagination";

@import "default/top";
@import "default/top-fv-slider";
@import "default/news";
@import "default/menu";
@import "default/shop";
@import "default/banquet";
@import "default/privacy";





