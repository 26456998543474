.fv{
  position: relative;
  width: 100%;
  &__sp-back{
    display: none;
    position: relative;
    z-index: 5;
    @include mediaM{
      display: block;
      position: absolute;
      z-index: 4;
      left: 0;
      bottom: -350px;
      clip-path: polygon(0 40%, 0 0, 100% 35%, 100% 75%);
      background-image: url('../img/common/header-back.png');
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      width: 100%;
      // padding-top: 100%;
      height: 550px;
    }
    @include mediaS{
      height: 450px;
      bottom: -275px;
    }
    @include mediaXS{
      height: auto;
      bottom: -220px;
      height: 350px;
    }
  }
  .sliderList{
    width: 100%;
    height: 100vh;
    position: relative;
    height: 900px;
    @include mediaLL{
      height: 770px;
    }
    @include mediaML{
      height: 700px;
    }
    @include mediaM{
      height: 600px;
    }
    @include mediaS{
      height: 500px;
    }
    @include mediaXS{
      height: 460px;
    }
    img{
      width: 100%;
      // height: 100%;
      object-fit: cover;
      display: block;
    }
  }
}
.top-news{
  background-image: url('../img/top/news-back.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 100%;
  @include mediaXS{
    background-image: url('../img/top/news-back-sp.png');
  }
  &__inner{
    @extend .content-extend;
    padding-top: 260px;
    padding-bottom: 320px;
    @include mediaM{
      padding-top: 30%;
      padding-bottom: 520px;
    }
  }
  &__title{
    font-size: 2rem;
    @include mediaS{
      font-size: 1.375rem;
    }
    @include mediaXS{
      margin-top: 64px;
    }
  }
  &__list{
    margin-top: 48px;
    max-width: 570px;
    width: 100%;
    position: relative;
    z-index: 3;
    @include mediaXS{
      margin-top: 40px;
    }
    &__item{
      padding-bottom: 8px;
      border-bottom:1px solid $black;
      &:nth-child(n + 2){
        margin-top: 60px;
        @include mediaXS{
          margin-top: 24px;
        }
      }
      &__link{
        display: flex;
        align-items: flex-start;
        padding: 0 6px;
        color: $black;
        @include mediaXS{
          flex-direction: column;
        }
        p{
          font-size: 0.875rem;
          line-height: 20px;
          // @include mediaXS{
          //   font-size: 0.75rem;
          //   line-height: 18px;
          // }
        }
        &__data{
          width: 110px;
          flex-shrink: 0;
          @include mediaXS{
            width: 100%;
          }
        }
        &__name{
          width: 100%;
          @include mediaXS{
            margin-top: 6px;
          }
        }
      }
    }
  }
}
.top-work{
  margin-top: -490px;
  position: relative;
  z-index: 6;
  clip-path: polygon(0 91%, 0 40%, 100% 3%, 100% 57%);
  @include mediaXS{
    clip-path: polygon(0 99%, 0 30%, 100% 3%, 100% 70%);
  }
  img{
    display: block;
    width: 100%;
    object-fit: cover;
    @include mediaXS{
      height: 500px;
    }
  }
}
.top-course{
  position: relative;
  z-index: 7;
  margin-top: -420px;
  background: $darkBlack;
  clip-path: polygon(0 0, 100% 8%, 100% 120%, 0% 100%);
  padding: 550px 0 200px;
  @include mediaM{
    padding: 50% 0 12%;
  }
  .bottom-line{
    bottom: -600px;
    position: absolute;
    z-index: 8;
    background-image: url('../img/top/news-back.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    left: 0;
    width: 100%;
    padding-top: 70%;
    clip-path: polygon(0 1%, 100% 51%, 100% 95%, 0 46%);
    @include mediaXL{
      bottom: -400px;
    }
    @include mediaL{
      bottom: -280px;
    }
    @include mediaM{
      // clip-path: polygon(0 1%, 100% 20%, 100% 70%, 0 50%);
      clip-path: polygon(0 1%, 100% 10%, 100% 80%, 0 71%);
      bottom: -9%;
    }
    @include mediaXS{
      clip-path: polygon(0 1%, 100% 10%, 100% 80%, 0 71%);
      bottom: -7%;
    }
  }
  &__image{
    width: 70%;
    margin-left: auto;
    @include mediaM{
      width: 81%;
    }
    img{
      display: block;
      width: 100%;
    }
  }
  &__title{
    margin-top: 70px;
    max-width: 440px;
    margin-left: auto;
    margin-right: 80px;
    @include mediaM{
      margin-right: 4%;
    }
    @include mediaS{
      max-width: 290px;
    }
    &__h2{
      display: flex;
      align-items: flex-end;
      .main{
        display: inline-block;
        line-height: 1;
        font-size: 2.625rem;
        color: $gold;
        padding: 4px 4px 8px;
        background: $white;
        @include mediaS{
          font-size: 1.625rem;
        }
      }
      .sub{
        display: block;
        margin-left: 5px;
        font-size: 1.375rem;
        color: $white;
        margin-bottom: 5px;
        @include mediaS{
          font-size: 1.125rem;
        }
      }
    }
    &__description{
      font-size: 1rem;
      line-height: 24px;
      color: $white;
      margin-top: 10px;
      @include mediaS{
        margin-top: 16px;
        line-height: 18px;
        font-size: 0.875rem;
      }
    }
  }
  &__carousel{
    padding: 200px 80px 0;
    margin: 0 auto;
    position: relative;
    z-index: 9;
    @include mediaM{
      padding: 0;
      margin-top: 25%;
    }
    &__inner{
      display: flex;
      align-items: flex-end;
      @include mediaM{
        flex-direction: column;
      }
    }
    &__main{
      width: 100%;
      margin:0 auto;
      position: relative;
      @include mediaM{
        width: 91%;
        margin: 0 auto 0 0;
      }
      .mainCarousel {
        position: relative;
        overflow: hidden;
        padding-top: 64.9%;
        width: 100%;
        .mainCarouselItem {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          transition:all 2s ease-in-out;
          // z-index: 0 !important;
          z-index: 1;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            // mask-image: linear-gradient(rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 1) 50%);
            // mask-repeat: no-repeat;
            // mask-position: 0 100%;
            // mask-size: 100% 0;
            //transition: all 4s ease;
          }
          // 表示するSliderItemのCSS
          &.active {
            opacity: 1;
            z-index: 3;
          }
        }
      }
      // & > .active{
      //   animation: fadeImg 0.8s;
      // }
      // img{
      //   display: block;
      //   width: 100%;
      // }
      &__title{
        z-index: 6;
        position: absolute;
        left: 40px;
        bottom: 30px;
        border: 5px solid #707070;
        background: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 16px 26px;
        @include mediaM{
          left: 4%;
        }
        @include mediaXS{
          padding: 8px 12px;
          bottom: 8px;
          font-size: 0.625rem;
          line-height: 1;
        }
        span{
          font-size: 1.25rem;
          display: block;
        }
      }
    }
    &__thumbnails{
      display: flex;
      flex-shrink: 0;
      flex-direction: column;
      width: 125px;
      margin: 0 80px -80px 36px;
      padding: 0;
      @include mediaM{
        display: none;
      }
      & li{
        cursor: pointer;
        opacity: 0.4;
        width: 100%;
        height: 118px;
        position: relative;
        overflow: hidden;
        // transition:all 5s;
        &:nth-child(n + 2){
          margin-top: 16px;
        }
        &:hover{
          opacity: 1;
        }
        &.current{
          opacity: 1;
        }
      }
      & img{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &__h3{
      margin-top: 16px;
      font-size: 1.375rem;
      @include mediaM{
        margin-left: 4%;
      }
      @include mediaS{
        font-size: 1.125rem;
      }
    }
    &__description{
      margin-top: 16px;
      font-size: 1rem;
      @include mediaM{
        margin-left: 4%;
      }
      @include mediaS{
        margin-top: 10px;
        font-size: 0.875rem;
        line-height: 20px;
      }
    }
  }
}
.top-dish{
  padding-top: 100px;
  margin-top: -100px;
  position: relative;
  @include mediaM{
    margin-top: 8%;
  }
  &__carousel{
    padding: 200px 80px 0;
    margin: 0 auto;
    position: relative;
    z-index: 9;
    @include mediaM{
      padding: 0;
    }
    &__inner{
      display: flex;
      align-items: flex-end;
    }
    &__main{
      width: 100%;
      margin:0 auto;
      position: relative;
      @include mediaM{
        margin: 0 0 0 auto;
        width: 91%;
      }
      .mainCarousel {
        position: relative;
        overflow: hidden;
        padding-top: 64.9%;
        width: 100%;
        .mainCarouselDish {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          transition:all 2s ease-in-out;
          // z-index: 0 !important;
          z-index: 1;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            // mask-image: linear-gradient(rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 1) 50%);
            // mask-repeat: no-repeat;
            // mask-position: 0 100%;
            // mask-size: 100% 0;
            //transition: all 4s ease;
          }
          // 表示するSliderItemのCSS
          &.active {
            opacity: 1;
            z-index: 3;
          }
        }
      }
      // & > .active{
      //   animation: fadeImg 0.8s;
      // }
      // img{
      //   display: block;
      //   width: 100%;
      // }
    }
    &__thumbnails{
      display: flex;
      flex-shrink: 0;
      flex-direction: column;
      width: 125px;
      margin: 0 80px -80px 36px;
      padding: 0;
      @include mediaM{
        display: none;
      }
      & li{
        cursor: pointer;
        opacity: 0.4;
        width: 100%;
        height: 118px;
        position: relative;
        overflow: hidden;
        // transition:all 5s;
        &:nth-child(n + 2){
          margin-top: 16px;
        }
        &:hover{
          opacity: 1;
        }
        &.current{
          opacity: 1;
        }
      }
      & img{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &__h3{
      margin-top: 16px;
      font-size: 1.375rem;
      @include mediaM{
        margin-right: 4%;
      }
      @include mediaS{
        font-size: 1.125rem;
      }
    }
    &__description{
      margin-top: 16px;
      font-size: 1rem;
      @include mediaM{
        margin-right: 4%;
      }
      @include mediaS{
        margin-top: 10px;
        font-size: 0.875rem;
        line-height: 20px;
      }
    }
  }
}
.top-shop{
  margin-top: 350px;
  @include mediaM{
    margin-top: 30%;
  }
  &__flex{
    display: flex;
    align-items: flex-end;
    &__left{
      width: 70%;
      @include mediaM{
        width: 78%;
      }
    }
    &__right{
      margin-left: -14.8%;
      width: 44.8%;
      margin-bottom: -14%;
      @include mediaM{
        margin-left: -30%;
        width: 60%;
      }
      @include mediaXS{
        margin-bottom: -35%;
      }
    }
    img{
      width: 100%;
      display: block;
      object-fit: cover;
    }
  }
  &__title{
    max-width: 320px;
    margin-left: 80px;
    margin-right: auto;
    margin-top: 14%;
    @include mediaM{
      margin-left: 4%;
      margin-top: 25%;
    }
    @include mediaXS{
      margin-top: 44%;
    }
    &__h2{
      display: flex;
      align-items: flex-end;
      .main{
        display: inline-block;
        line-height: 1;
        font-size: 2.625rem;
        color: $black;
        padding: 4px 6px 8px;
        position: relative;
        @include mediaS{
          font-size: 1.625rem;
        }
        &:after{
          content: '';
          position: absolute;
          z-index: -1;
          top: 0;
          left: 0;
          background-image: url('../img/top/shop/text-back.png');
          background-repeat: no-repeat;
          background-position: center center;
          background-size: cover;
          width: 54px;
          height: 54px;
          @include mediaS{
            width: 35px;
            height: 35px;
          }
        }
      }
      .sub{
        display: block;
        margin-left: 5px;
        font-size: 1.375rem;
        margin-bottom: 5px;
        @include mediaS{
          font-size: 1.125rem;
        }
      }
    }
    &__description{
      font-size: 1rem;
      line-height: 24px;
      margin-top: 10px;
    }
  }
  &__image{
    position: relative;
    @include mediaM{
      margin-top: 20%;
    }
    &__top{
      width: 56.8%;
      margin-left: auto;
      margin-right: 80px;
      @include mediaM{
        width: 79.4%;
        margin-right: 0;
      }
    }
    &__bottom{
      margin-top: 72px;
      width: 45.7%;
      margin-right: auto;
      @include mediaM{
        width: 64.2%;
      }
      @include mediaS{
        margin-top: 40px;
      }
    }
    img{
      display: block;
      width: 100%;
    }
    &:after{
      content: '';
      position: absolute;
      z-index: -1;
      top: 50%;
      left: 0%;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      width: 100%;
      padding-top: 50%;
      background-image: url('../img/top/shop/back.png');
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      clip-path: polygon(0 45%, 100% 13%, 100% 60%, 0 90%);
    }
  }
  &__mind{
    margin-top: 200px;
    max-width: 1060px;
    margin: 200px auto 0;
    width: 100%;
    padding: 0 80px;
    @include mediaM{
      padding: 0 4%;
      margin: 16% auto 0;
    }
    img{
      display: block;
      width: 100%;
      @include mediaM{
        max-width: 700px;
        margin: auto;
      }
    }
    &__title{
      max-width: 610px;
      margin: 32px auto 0;
      @include mediaS{
        max-width: 500px;
      }
      &__h2{
        display: flex;
        align-items: flex-end;
        justify-content: center;
        .main{
          display: inline-block;
          line-height: 1;
          font-size: 2.625rem;
          color: $black;
          padding: 4px 6px 8px;
          position: relative;
          background: $purple;
          color: $white;
          @include mediaS{
            font-size: 1.625rem;
          }
        }
        .sub{
          display: block;
          margin-left: 5px;
          font-size: 1.375rem;
          margin-bottom: 5px;
          @include mediaS{
            font-size: 1.125rem;
          }
        }
      }
      &__description{
        font-size: 1rem;
        line-height: 24px;
        margin-top: 20px;
        @include mediaS{
          margin-top: 16px;
          line-height: 18px;
          font-size: 0.875rem;
        }
      }
    }
  }
}
.top-about{
  clip-path: polygon(0 0, 100% 12%, 100% 100%, 0 100%);
  background: $darkBlack;
  margin-top: 200px;
  padding: 500px 0 180px;
  @include mediaM{
    padding: 45% 0 35%;
    clip-path: polygon(0 0, 100% 4%, 100% 100%, 0 100%);
  }
  &__flex{
    display: flex;
    align-items: center;
    @include mediaM{
      flex-direction: column;
      padding: 0 4%;
    }
    &__image{
      width: 52%;
      @include mediaM{
        width: 100%;
        max-width: 650px;
      }
      img{
        display: block;
        width: 100%;
      }
    }
    &__content{
      padding: 0 120px 0 90px;
      width: 48%;
      color: $white;
      @include mediaML{
        padding: 0 80px 0 48px;
      }
      @include mediaM{
        width: 100%;
        padding: 0;
        margin-top: 30px;
      }
      &__title{
        font-size: 2rem;
        @include mediaS{
          font-size: 1.375rem;
        }
      }
      &__description{
        margin-top: 24px;
        line-height: 24px;
        font-size: 1rem;
        @include mediaS{
          font-size: 0.875rem;
          line-height: 22px;
          margin-top: 16px;
        }
      }
      &__name{
        margin-top: 48px;
        font-size: 1rem;
        text-align: right;
        max-width: 350px;
        @include mediaS{
          font-size: 0.875rem;
          margin-top: 32px;
          margin-top: 16px;
          
        }
      }
    }
  }
  &__grid{
    display: flex;
    align-items: stretch;
    margin-top: 252px;
    @include mediaM{
      margin-top: 21%;
    }
    .left{
      width: 39.1%;
    }
    .right{
      width: 60.9%;
    }
    img{
      display: block;
    }
  }
  &__bottom{
    width: 100%;
    margin-top: 252px;
    @include mediaM{
      margin-top: 21%;
    }
    img{
      display: block;
      width: 100%;
    }
  }
}
.top-access{
  margin-top: 130px;
  @include mediaS{
    margin-top: 80px;
  }
  &__inner{
    display: flex;
    align-items: flex-end;
    @include mediaM{
      flex-direction: column;
    }
    &__image{
      width: 65%;
      @include mediaLL{
        width: calc(100% - 460px);
      }
      @include mediaM{
        width: 100%;
      }
      &__main{
        display: block;
        width: 100%;
      }
    }
    &__content{
      width: 35%;
      padding: 0 80px 0 62px;
      min-width: 460px;
      flex-shrink: 0;
      margin-bottom: -40px;
      @include mediaM{
        width: 100%;
        padding: 0 4%;
        margin-top: 24px;
        min-width: inherit;
      }
      &__title{
        font-size: 1.625rem;
        @include mediaS{
          font-size: 1.375rem;
        }
      }
      dl{
        margin-top: 24px;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        dt{
          width: 150px;
          flex-shrink: 0;
          font-size: 1rem;
          line-height: 24px;
          &.space{
            margin-top: 16px;
            @include mediaS{
              margin-top: 24px;
            }
          }
          @include mediaS{
            font-size: 0.875rem;
            line-height: 20px;
            width: 117px;
            padding-left: 12px;
          }
        }
        dd{
          width: calc(100% - 150px);
          font-size: 1rem;
          line-height: 24px;
          @include mediaS{
            line-height: 20px;
            font-size: 0.875rem;
            width: calc(100% - 117px);
          }
          &.space{
            margin-top: 16px;
            @include mediaS{
              margin-top: 24px;
            }
          }
        }
      }
    }
  }
  &__map{
    margin-top: 270px;
    width: 100%;
    @include mediaM{
      margin-top: 24%;
    }
    iframe{
      width: 100%;
      height: 530px;
      @include mediaM{
        height: 400px;
      }
      @include mediaS{
        height: 350px;
      }
      @include mediaXS{
        height: 300px;
      }
    }
  }
}
@keyframes fadeImg {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}