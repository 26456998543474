.banquet{
  &__title{
    font-size: 2rem;
    @include mediaM{
      font-size: 1.5rem;
    }
    @include mediaXS{
      font-size: 1.25rem;
    }
    &.space{
      margin-top: 150px;
      margin-left: 80px;
      @include mediaM{
        margin-left: 4%;
        margin-top: 15%;
      }
    }
  }
  &__introduction{
    position: relative;
    z-index: 10;
    padding: 0 80px;
    margin-top: 64px;
    @include mediaM{
      padding: 0 4%;
      margin-top: 48px;
    }
    &__title{
      text-align: center;
      font-size: 2rem;
      @include mediaM{
        font-size: 1.5rem;
      }
      @include mediaXS{
        font-size: 1.25rem;
      }
    }
    &__list{
      margin-top: 60px;
      @include mediaM{
        margin-top: 40px;
      }
      &__item{
        &:nth-child(n+ 2){
          margin-top: 50px;
          @include mediaM{
            margin-top: 32px;
          }
        }
        padding: 24px 32px 42px 12%;
        position: relative;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        @include mediaXS{
          padding: 20px 24px 32px 24px;
        }
        &.memorial{
          color: $white;
          background-image: url('../img/banquet/back01.jpg');
        }
        &.auspicious{
          background-image: url('../img/banquet/back02.jpg');
        }
        &.party{
          background-image: url('../img/banquet/back03.jpg');
        }
        &__title{
          font-size: 1.375rem;
          @include mediaM{
            font-size: 1.125rem;
          }
          // @include mediaXS{
          //   font-size: 1rem;
          // }
        }
        &__detail{
          font-size: 1rem;
          line-height: 22px;
          margin-top: 10px;
          @include mediaM{
            font-size: 0.875rem;
            line-height: 20px;
          }
        }
      }
    }
  }
  &__detail{
    font-size: 1.125rem;
    line-height: 26px;
    margin-top: 10px;
    @extend .noto-serif;
    @include mediaM{
      font-size: 1rem;
      line-height: 24px;
    }
    @include mediaXS{
      font-size: 0.875rem;
      line-height: 22px;
    }
  }
  &__plan{
    margin-top: 200px;
    @include mediaM{
      margin-top: 20%;
    }
    &__title-block{
      padding: 0 80px;
      @include mediaM{
        padding: 0 4%;
      }
    }
    &__flex{
      padding-top: 80px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      position: relative;
      padding-left: 80px;
      padding-right: 80px;
      @include mediaM{
        padding-left: 4%;
        padding-right: 4%;
      }
      @include mediaM{
        align-items: flex-start;
        padding-top: 10%;
        flex-direction: column-reverse;
      }
      &:after{
        content: '';
        position: absolute;
        z-index: -1;
        background-image: url('../img/banquet/plan-back.png');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        top: 0;
        left: 0;
        width: 100%;
        padding-top: 57%;
        clip-path: polygon(0 50%, 100% 0%, 100% 50%, 0 100%);
        @include mediaXL{
          padding-top: 65%;
        }
        @include mediaM{
          padding-top: 100%;
        }
      }
      &__list{
        width: 52.1%;
        @include mediaM{
          width: 100%;
          margin-top: 48px;
        }
        @include mediaXS{
          margin-top: 24px;
        }
        &__item{
          padding-bottom: 8px;
          border-bottom: 1px solid #707070;
          display: flex;
          align-items: flex-end;
          padding-left: 8px;
          padding-right: 8px;
          &:nth-child(n + 2){
            margin-top: 12px;
            @include mediaM{
              margin-top:20px;
            }
          }
          @include mediaXS{
            flex-direction: column;
            padding-left: 4px;
            padding-right: 4px;
          }
          p{
            font-size: 1rem;
            @extend .noto-serif;
            @include mediaM{
              font-size: 0.875rem;
            }
          }
          .name{
            width: 100%;
          }
          .price{
            flex-shrink: 0;
            width: 112px;
            text-align: left;
            padding-left: 10px;
            @include mediaXS{
              text-align: right;
              width: 100%;
              margin-top: 6px;
            }
          }
        }
      }
      &__image{
        width: 42.6%;
        @include mediaM{
          width: 91%;
        }
        img{
          display: block;
          width: 100%;
        }
        &__detail{
          font-size: 1rem;
          margin-top: 20px;
          @extend .noto-serif;
            @include mediaM{
              font-size: 0.875rem;
            }
        }
      }
    }
  }
}