@charset "UTF-8";
/*# sourceMappingURL=../css/style.css.map */
/*--基本スタイル--*/
/*--mixin--*/
/*共通部分*/
html {
  font-size: 100%;
  letter-spacing: 0.22em;
  visibility: hidden;
  opacity: 0;
}

html.wf-active {
  visibility: visible;
  opacity: 1;
}

body {
  font-family: "M PLUS Rounded 1c", sans-serif, apple-system, blinkMacSystemFont, "Helvetica Neue", "Segoe UI", YuGothicM, YuGothic, Meiryo, Arial, "Yu Gothic Medium", "ヒラギノ角ゴ Pro W3", sans-serif;
  line-height: 1.5;
  color: #404140;
  font-weight: 400;
  letter-spacing: 1.3px;
  overflow: hidden;
}
@media screen and (max-width: 768px) {
  body {
    font-size: 0.875rem;
  }
}
@media screen and (max-width: 576px) {
  body {
    font-size: 0.75rem;
  }
}

body.fixed {
  width: 100%;
  position: fixed;
  overflow: hidden;
}

.max-extend {
  max-width: 1920px;
  margin: 0 auto;
}

.max-extend-inner {
  max-width: 1366px;
  width: 100%;
  margin: 0 auto;
  padding: 0 4%;
}
.max-extend-inner.mb {
  margin: 160px auto 0px;
}
@media screen and (max-width: 768px) {
  .max-extend-inner.mb {
    margin: 60px auto 0px;
  }
}

.content-extend {
  max-width: 1190px;
  width: 90%;
  margin: 0 auto;
}

.wrapper {
  padding: 0 4%;
}

.content-wrapper {
  padding: 0 5%;
}

h1, h2 {
  font-size: 2rem;
  font-weight: 500;
}
@media screen and (max-width: 768px) {
  h1, h2 {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 576px) {
  h1, h2 {
    font-size: 1.25rem;
  }
}

h3, h4, h5, h6 {
  font-size: 1.375rem;
  line-height: 1;
  font-weight: 400;
}
@media screen and (max-width: 768px) {
  h3, h4, h5, h6 {
    font-size: 1.25rem;
  }
}
@media screen and (max-width: 576px) {
  h3, h4, h5, h6 {
    font-size: 1rem;
  }
}

b {
  font-weight: 700;
}

p {
  line-height: 1;
  font-size: 1rem;
}
@media screen and (max-width: 768px) {
  p {
    font-size: 0.875rem;
  }
}
@media screen and (max-width: 576px) {
  p {
    font-size: 0.75rem;
  }
}

.flex-reverse {
  flex-direction: row-reverse;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
}

.sp-br {
  display: none;
}
@media screen and (max-width: 768px) {
  .sp-br {
    display: block;
  }
}

.pc-show {
  display: block;
}
@media screen and (max-width: 768px) {
  .pc-show {
    display: none;
  }
}

.sp-show {
  display: none;
}
@media screen and (max-width: 768px) {
  .sp-show {
    display: block;
  }
}

.sp-xs-none {
  display: none;
}
@media screen and (max-width: 768px) {
  .sp-xs-none {
    display: block;
  }
}
@media screen and (max-width: 576px) {
  .sp-xs-none {
    display: none;
  }
}

.sp-xs-show {
  display: none;
}
@media screen and (max-width: 576px) {
  .sp-xs-show {
    display: block;
  }
}

.xs-show {
  display: none;
}
@media screen and (max-width: 576px) {
  .xs-show {
    display: block;
  }
}

.xs-none {
  display: block;
}
@media screen and (max-width: 576px) {
  .xs-none {
    display: none;
  }
}

ul {
  list-style: none;
}

select {
  appearance: revert;
}

table {
  border-collapse: collapse;
  width: 100%;
  text-align: left;
}
@media screen and (max-width: 576px) {
  table th, table td {
    font-size: 0.875rem;
  }
}

ol {
  padding-left: 20px;
}

ul {
  list-style: none;
}

.back-inherit {
  background-color: inherit !important;
}

.text-align-center {
  text-align: center;
}

.mr-right {
  margin-right: 4%;
}

.flex {
  display: flex;
}
@media screen and (max-width: 768px) {
  .flex {
    display: block;
  }
}

.flex-warp {
  flex-wrap: wrap;
}

.flex-as {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}
.justify-end {
  justify-content: end;
}
.justify-around {
  justify-content: space-around;
}
.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.no-warp {
  white-space: nowrap;
}

.pointer {
  cursor: pointer;
}

/* フォントカラー */
.fc-white {
  color: #ffffff;
}

/* bottom 三角 */
.triangle-bottom {
  margin: 48px auto;
  width: 0;
  height: 0;
  border-left: 250px solid transparent;
  border-right: 250px solid transparent;
  border-top: 64px solid #404140;
}
@media screen and (max-width: 576px) {
  .triangle-bottom {
    border-left: 150px solid transparent;
    border-right: 150px solid transparent;
    border-top: 50px solid #404140;
  }
}

.vertical-font {
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
}

.position-right {
  text-align: right;
}

.position-left {
  text-align: left;
}

.medium {
  font-weight: 500;
}

.semi-bold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

.d-inline {
  display: inline-block;
}

/*----scrollFadein----*/
.scroll-fadein {
  opacity: 0;
}
.scroll-fadein.show {
  animation: up-fadein 1s ease-out forwards;
}

@keyframes up-fadein {
  from {
    opacity: 0;
    transform: translateY(40px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
/*時間差でフェイドイン*/
.scroll-fadein-delay1 {
  opacity: 0;
}
.scroll-fadein-delay1.show {
  animation: up-fadein 1s ease-out forwards;
}
.scroll-fadein-delay1.show:nth-of-type(1) {
  animation-delay: 0s;
}
.scroll-fadein-delay1.show:nth-of-type(2) {
  animation-delay: 0.5s;
}
.scroll-fadein-delay1.show:nth-of-type(3) {
  animation-delay: 1s;
}
.scroll-fadein-delay1.show:nth-of-type(4) {
  animation-delay: 1.5s;
}
.scroll-fadein-delay1.show:nth-of-type(5) {
  animation-delay: 2s;
}
.scroll-fadein-delay1.show:nth-of-type(6) {
  animation-delay: 2.5s;
}
.scroll-fadein-delay1.show:nth-of-type(7) {
  animation-delay: 3s;
}
.scroll-fadein-delay1.show:nth-of-type(8) {
  animation-delay: 3.5s;
}
.scroll-fadein-delay1.show:nth-of-type(9) {
  animation-delay: 4s;
}
.scroll-fadein-delay1.show:nth-of-type(10) {
  animation-delay: 4.5s;
}
.scroll-fadein-delay1.show:nth-of-type(11) {
  animation-delay: 5s;
}
.scroll-fadein-delay1.show:nth-of-type(12) {
  animation-delay: 5.5s;
}
.scroll-fadein-delay1.show:nth-of-type(13) {
  animation-delay: 6s;
}
.scroll-fadein-delay1.show:nth-of-type(14) {
  animation-delay: 6.5s;
}
.scroll-fadein-delay1.show:nth-of-type(15) {
  animation-delay: 7s;
}
.scroll-fadein-delay1.show:nth-of-type(16) {
  animation-delay: 7.5s;
}
.scroll-fadein-delay1.show:nth-of-type(17) {
  animation-delay: 8s;
}
.scroll-fadein-delay1.show:nth-of-type(18) {
  animation-delay: 8.5s;
}
.scroll-fadein-delay1.show:nth-of-type(19) {
  animation-delay: 9s;
}
.scroll-fadein-delay1.show:nth-of-type(20) {
  animation-delay: 9.5s;
}

.scroll-fadein-delay2 {
  opacity: 0;
}
.scroll-fadein-delay2.show {
  animation: up-fadein 1s ease-out forwards;
}
.scroll-fadein-delay2.show:nth-of-type(1) {
  animation-delay: 0s;
}
.scroll-fadein-delay2.show:nth-of-type(2) {
  animation-delay: 0.5s;
}
.scroll-fadein-delay2.show:nth-of-type(3) {
  animation-delay: 1s;
}
.scroll-fadein-delay2.show:nth-of-type(4) {
  animation-delay: 1.5s;
}
.scroll-fadein-delay2.show:nth-of-type(5) {
  animation-delay: 2s;
}
.scroll-fadein-delay2.show:nth-of-type(6) {
  animation-delay: 2.5s;
}
.scroll-fadein-delay2.show:nth-of-type(7) {
  animation-delay: 3s;
}
.scroll-fadein-delay2.show:nth-of-type(8) {
  animation-delay: 3.5s;
}
.scroll-fadein-delay2.show:nth-of-type(9) {
  animation-delay: 4s;
}
.scroll-fadein-delay2.show:nth-of-type(10) {
  animation-delay: 4.5s;
}
.scroll-fadein-delay2.show:nth-of-type(11) {
  animation-delay: 5s;
}
.scroll-fadein-delay2.show:nth-of-type(12) {
  animation-delay: 5.5s;
}
.scroll-fadein-delay2.show:nth-of-type(13) {
  animation-delay: 6s;
}
.scroll-fadein-delay2.show:nth-of-type(14) {
  animation-delay: 6.5s;
}
.scroll-fadein-delay2.show:nth-of-type(15) {
  animation-delay: 7s;
}
.scroll-fadein-delay2.show:nth-of-type(16) {
  animation-delay: 7.5s;
}
.scroll-fadein-delay2.show:nth-of-type(17) {
  animation-delay: 8s;
}
.scroll-fadein-delay2.show:nth-of-type(18) {
  animation-delay: 8.5s;
}
.scroll-fadein-delay2.show:nth-of-type(19) {
  animation-delay: 9s;
}
.scroll-fadein-delay2.show:nth-of-type(20) {
  animation-delay: 9.5s;
}

.scroll-fadein-delay3 {
  opacity: 0;
}
.scroll-fadein-delay3.show {
  animation: up-fadein 1s ease-out forwards;
}
.scroll-fadein-delay3.show:nth-of-type(1) {
  animation-delay: 0s;
}
.scroll-fadein-delay3.show:nth-of-type(2) {
  animation-delay: 0.5s;
}
.scroll-fadein-delay3.show:nth-of-type(3) {
  animation-delay: 1s;
}
.scroll-fadein-delay3.show:nth-of-type(4) {
  animation-delay: 1.5s;
}
.scroll-fadein-delay3.show:nth-of-type(5) {
  animation-delay: 2s;
}
.scroll-fadein-delay3.show:nth-of-type(6) {
  animation-delay: 2.5s;
}
.scroll-fadein-delay3.show:nth-of-type(7) {
  animation-delay: 3s;
}
.scroll-fadein-delay3.show:nth-of-type(8) {
  animation-delay: 3.5s;
}
.scroll-fadein-delay3.show:nth-of-type(9) {
  animation-delay: 4s;
}
.scroll-fadein-delay3.show:nth-of-type(10) {
  animation-delay: 4.5s;
}
.scroll-fadein-delay3.show:nth-of-type(11) {
  animation-delay: 5s;
}
.scroll-fadein-delay3.show:nth-of-type(12) {
  animation-delay: 5.5s;
}
.scroll-fadein-delay3.show:nth-of-type(13) {
  animation-delay: 6s;
}
.scroll-fadein-delay3.show:nth-of-type(14) {
  animation-delay: 6.5s;
}
.scroll-fadein-delay3.show:nth-of-type(15) {
  animation-delay: 7s;
}
.scroll-fadein-delay3.show:nth-of-type(16) {
  animation-delay: 7.5s;
}
.scroll-fadein-delay3.show:nth-of-type(17) {
  animation-delay: 8s;
}
.scroll-fadein-delay3.show:nth-of-type(18) {
  animation-delay: 8.5s;
}
.scroll-fadein-delay3.show:nth-of-type(19) {
  animation-delay: 9s;
}
.scroll-fadein-delay3.show:nth-of-type(20) {
  animation-delay: 9.5s;
}

.scroll-fadein-delay4 {
  opacity: 0;
}
.scroll-fadein-delay4.show {
  animation: up-fadein 1s ease-out forwards;
}
.scroll-fadein-delay4.show:nth-of-type(1) {
  animation-delay: 0s;
}
.scroll-fadein-delay4.show:nth-of-type(2) {
  animation-delay: 0.5s;
}
.scroll-fadein-delay4.show:nth-of-type(3) {
  animation-delay: 1s;
}
.scroll-fadein-delay4.show:nth-of-type(4) {
  animation-delay: 1.5s;
}
.scroll-fadein-delay4.show:nth-of-type(5) {
  animation-delay: 2s;
}
.scroll-fadein-delay4.show:nth-of-type(6) {
  animation-delay: 2.5s;
}
.scroll-fadein-delay4.show:nth-of-type(7) {
  animation-delay: 3s;
}
.scroll-fadein-delay4.show:nth-of-type(8) {
  animation-delay: 3.5s;
}
.scroll-fadein-delay4.show:nth-of-type(9) {
  animation-delay: 4s;
}
.scroll-fadein-delay4.show:nth-of-type(10) {
  animation-delay: 4.5s;
}
.scroll-fadein-delay4.show:nth-of-type(11) {
  animation-delay: 5s;
}
.scroll-fadein-delay4.show:nth-of-type(12) {
  animation-delay: 5.5s;
}
.scroll-fadein-delay4.show:nth-of-type(13) {
  animation-delay: 6s;
}
.scroll-fadein-delay4.show:nth-of-type(14) {
  animation-delay: 6.5s;
}
.scroll-fadein-delay4.show:nth-of-type(15) {
  animation-delay: 7s;
}
.scroll-fadein-delay4.show:nth-of-type(16) {
  animation-delay: 7.5s;
}
.scroll-fadein-delay4.show:nth-of-type(17) {
  animation-delay: 8s;
}
.scroll-fadein-delay4.show:nth-of-type(18) {
  animation-delay: 8.5s;
}
.scroll-fadein-delay4.show:nth-of-type(19) {
  animation-delay: 9s;
}
.scroll-fadein-delay4.show:nth-of-type(20) {
  animation-delay: 9.5s;
}

.scroll-fadein-delay5 {
  opacity: 0;
}
.scroll-fadein-delay5.show {
  animation: up-fadein 1s ease-out forwards;
}
.scroll-fadein-delay5.show:nth-of-type(1) {
  animation-delay: 0s;
}
.scroll-fadein-delay5.show:nth-of-type(2) {
  animation-delay: 0.5s;
}
.scroll-fadein-delay5.show:nth-of-type(3) {
  animation-delay: 1s;
}
.scroll-fadein-delay5.show:nth-of-type(4) {
  animation-delay: 1.5s;
}
.scroll-fadein-delay5.show:nth-of-type(5) {
  animation-delay: 2s;
}
.scroll-fadein-delay5.show:nth-of-type(6) {
  animation-delay: 2.5s;
}
.scroll-fadein-delay5.show:nth-of-type(7) {
  animation-delay: 3s;
}
.scroll-fadein-delay5.show:nth-of-type(8) {
  animation-delay: 3.5s;
}
.scroll-fadein-delay5.show:nth-of-type(9) {
  animation-delay: 4s;
}
.scroll-fadein-delay5.show:nth-of-type(10) {
  animation-delay: 4.5s;
}
.scroll-fadein-delay5.show:nth-of-type(11) {
  animation-delay: 5s;
}
.scroll-fadein-delay5.show:nth-of-type(12) {
  animation-delay: 5.5s;
}
.scroll-fadein-delay5.show:nth-of-type(13) {
  animation-delay: 6s;
}
.scroll-fadein-delay5.show:nth-of-type(14) {
  animation-delay: 6.5s;
}
.scroll-fadein-delay5.show:nth-of-type(15) {
  animation-delay: 7s;
}
.scroll-fadein-delay5.show:nth-of-type(16) {
  animation-delay: 7.5s;
}
.scroll-fadein-delay5.show:nth-of-type(17) {
  animation-delay: 8s;
}
.scroll-fadein-delay5.show:nth-of-type(18) {
  animation-delay: 8.5s;
}
.scroll-fadein-delay5.show:nth-of-type(19) {
  animation-delay: 9s;
}
.scroll-fadein-delay5.show:nth-of-type(20) {
  animation-delay: 9.5s;
}

.scroll-fadein-delay6 {
  opacity: 0;
}
.scroll-fadein-delay6.show {
  animation: up-fadein 1s ease-out forwards;
}
.scroll-fadein-delay6.show:nth-of-type(1) {
  animation-delay: 0s;
}
.scroll-fadein-delay6.show:nth-of-type(2) {
  animation-delay: 0.5s;
}
.scroll-fadein-delay6.show:nth-of-type(3) {
  animation-delay: 1s;
}
.scroll-fadein-delay6.show:nth-of-type(4) {
  animation-delay: 1.5s;
}
.scroll-fadein-delay6.show:nth-of-type(5) {
  animation-delay: 2s;
}
.scroll-fadein-delay6.show:nth-of-type(6) {
  animation-delay: 2.5s;
}
.scroll-fadein-delay6.show:nth-of-type(7) {
  animation-delay: 3s;
}
.scroll-fadein-delay6.show:nth-of-type(8) {
  animation-delay: 3.5s;
}
.scroll-fadein-delay6.show:nth-of-type(9) {
  animation-delay: 4s;
}
.scroll-fadein-delay6.show:nth-of-type(10) {
  animation-delay: 4.5s;
}
.scroll-fadein-delay6.show:nth-of-type(11) {
  animation-delay: 5s;
}
.scroll-fadein-delay6.show:nth-of-type(12) {
  animation-delay: 5.5s;
}
.scroll-fadein-delay6.show:nth-of-type(13) {
  animation-delay: 6s;
}
.scroll-fadein-delay6.show:nth-of-type(14) {
  animation-delay: 6.5s;
}
.scroll-fadein-delay6.show:nth-of-type(15) {
  animation-delay: 7s;
}
.scroll-fadein-delay6.show:nth-of-type(16) {
  animation-delay: 7.5s;
}
.scroll-fadein-delay6.show:nth-of-type(17) {
  animation-delay: 8s;
}
.scroll-fadein-delay6.show:nth-of-type(18) {
  animation-delay: 8.5s;
}
.scroll-fadein-delay6.show:nth-of-type(19) {
  animation-delay: 9s;
}
.scroll-fadein-delay6.show:nth-of-type(20) {
  animation-delay: 9.5s;
}

.scroll-fadein-delay7 {
  opacity: 0;
}
.scroll-fadein-delay7.show {
  animation: up-fadein 1s ease-out forwards;
}
.scroll-fadein-delay7.show:nth-of-type(1) {
  animation-delay: 0s;
}
.scroll-fadein-delay7.show:nth-of-type(2) {
  animation-delay: 0.5s;
}
.scroll-fadein-delay7.show:nth-of-type(3) {
  animation-delay: 1s;
}
.scroll-fadein-delay7.show:nth-of-type(4) {
  animation-delay: 1.5s;
}
.scroll-fadein-delay7.show:nth-of-type(5) {
  animation-delay: 2s;
}
.scroll-fadein-delay7.show:nth-of-type(6) {
  animation-delay: 2.5s;
}
.scroll-fadein-delay7.show:nth-of-type(7) {
  animation-delay: 3s;
}
.scroll-fadein-delay7.show:nth-of-type(8) {
  animation-delay: 3.5s;
}
.scroll-fadein-delay7.show:nth-of-type(9) {
  animation-delay: 4s;
}
.scroll-fadein-delay7.show:nth-of-type(10) {
  animation-delay: 4.5s;
}
.scroll-fadein-delay7.show:nth-of-type(11) {
  animation-delay: 5s;
}
.scroll-fadein-delay7.show:nth-of-type(12) {
  animation-delay: 5.5s;
}
.scroll-fadein-delay7.show:nth-of-type(13) {
  animation-delay: 6s;
}
.scroll-fadein-delay7.show:nth-of-type(14) {
  animation-delay: 6.5s;
}
.scroll-fadein-delay7.show:nth-of-type(15) {
  animation-delay: 7s;
}
.scroll-fadein-delay7.show:nth-of-type(16) {
  animation-delay: 7.5s;
}
.scroll-fadein-delay7.show:nth-of-type(17) {
  animation-delay: 8s;
}
.scroll-fadein-delay7.show:nth-of-type(18) {
  animation-delay: 8.5s;
}
.scroll-fadein-delay7.show:nth-of-type(19) {
  animation-delay: 9s;
}
.scroll-fadein-delay7.show:nth-of-type(20) {
  animation-delay: 9.5s;
}

.scroll-fadein-delay8 {
  opacity: 0;
}
.scroll-fadein-delay8.show {
  animation: up-fadein 1s ease-out forwards;
}
.scroll-fadein-delay8.show:nth-of-type(1) {
  animation-delay: 0s;
}
.scroll-fadein-delay8.show:nth-of-type(2) {
  animation-delay: 0.5s;
}
.scroll-fadein-delay8.show:nth-of-type(3) {
  animation-delay: 1s;
}
.scroll-fadein-delay8.show:nth-of-type(4) {
  animation-delay: 1.5s;
}
.scroll-fadein-delay8.show:nth-of-type(5) {
  animation-delay: 2s;
}
.scroll-fadein-delay8.show:nth-of-type(6) {
  animation-delay: 2.5s;
}
.scroll-fadein-delay8.show:nth-of-type(7) {
  animation-delay: 3s;
}
.scroll-fadein-delay8.show:nth-of-type(8) {
  animation-delay: 3.5s;
}
.scroll-fadein-delay8.show:nth-of-type(9) {
  animation-delay: 4s;
}
.scroll-fadein-delay8.show:nth-of-type(10) {
  animation-delay: 4.5s;
}
.scroll-fadein-delay8.show:nth-of-type(11) {
  animation-delay: 5s;
}
.scroll-fadein-delay8.show:nth-of-type(12) {
  animation-delay: 5.5s;
}
.scroll-fadein-delay8.show:nth-of-type(13) {
  animation-delay: 6s;
}
.scroll-fadein-delay8.show:nth-of-type(14) {
  animation-delay: 6.5s;
}
.scroll-fadein-delay8.show:nth-of-type(15) {
  animation-delay: 7s;
}
.scroll-fadein-delay8.show:nth-of-type(16) {
  animation-delay: 7.5s;
}
.scroll-fadein-delay8.show:nth-of-type(17) {
  animation-delay: 8s;
}
.scroll-fadein-delay8.show:nth-of-type(18) {
  animation-delay: 8.5s;
}
.scroll-fadein-delay8.show:nth-of-type(19) {
  animation-delay: 9s;
}
.scroll-fadein-delay8.show:nth-of-type(20) {
  animation-delay: 9.5s;
}

.scroll-fadein-delay9 {
  opacity: 0;
}
.scroll-fadein-delay9.show {
  animation: up-fadein 1s ease-out forwards;
}
.scroll-fadein-delay9.show:nth-of-type(1) {
  animation-delay: 0s;
}
.scroll-fadein-delay9.show:nth-of-type(2) {
  animation-delay: 0.5s;
}
.scroll-fadein-delay9.show:nth-of-type(3) {
  animation-delay: 1s;
}
.scroll-fadein-delay9.show:nth-of-type(4) {
  animation-delay: 1.5s;
}
.scroll-fadein-delay9.show:nth-of-type(5) {
  animation-delay: 2s;
}
.scroll-fadein-delay9.show:nth-of-type(6) {
  animation-delay: 2.5s;
}
.scroll-fadein-delay9.show:nth-of-type(7) {
  animation-delay: 3s;
}
.scroll-fadein-delay9.show:nth-of-type(8) {
  animation-delay: 3.5s;
}
.scroll-fadein-delay9.show:nth-of-type(9) {
  animation-delay: 4s;
}
.scroll-fadein-delay9.show:nth-of-type(10) {
  animation-delay: 4.5s;
}
.scroll-fadein-delay9.show:nth-of-type(11) {
  animation-delay: 5s;
}
.scroll-fadein-delay9.show:nth-of-type(12) {
  animation-delay: 5.5s;
}
.scroll-fadein-delay9.show:nth-of-type(13) {
  animation-delay: 6s;
}
.scroll-fadein-delay9.show:nth-of-type(14) {
  animation-delay: 6.5s;
}
.scroll-fadein-delay9.show:nth-of-type(15) {
  animation-delay: 7s;
}
.scroll-fadein-delay9.show:nth-of-type(16) {
  animation-delay: 7.5s;
}
.scroll-fadein-delay9.show:nth-of-type(17) {
  animation-delay: 8s;
}
.scroll-fadein-delay9.show:nth-of-type(18) {
  animation-delay: 8.5s;
}
.scroll-fadein-delay9.show:nth-of-type(19) {
  animation-delay: 9s;
}
.scroll-fadein-delay9.show:nth-of-type(20) {
  animation-delay: 9.5s;
}

.scroll-fadein-delay10 {
  opacity: 0;
}
.scroll-fadein-delay10.show {
  animation: up-fadein 1s ease-out forwards;
}
.scroll-fadein-delay10.show:nth-of-type(1) {
  animation-delay: 0s;
}
.scroll-fadein-delay10.show:nth-of-type(2) {
  animation-delay: 0.5s;
}
.scroll-fadein-delay10.show:nth-of-type(3) {
  animation-delay: 1s;
}
.scroll-fadein-delay10.show:nth-of-type(4) {
  animation-delay: 1.5s;
}
.scroll-fadein-delay10.show:nth-of-type(5) {
  animation-delay: 2s;
}
.scroll-fadein-delay10.show:nth-of-type(6) {
  animation-delay: 2.5s;
}
.scroll-fadein-delay10.show:nth-of-type(7) {
  animation-delay: 3s;
}
.scroll-fadein-delay10.show:nth-of-type(8) {
  animation-delay: 3.5s;
}
.scroll-fadein-delay10.show:nth-of-type(9) {
  animation-delay: 4s;
}
.scroll-fadein-delay10.show:nth-of-type(10) {
  animation-delay: 4.5s;
}
.scroll-fadein-delay10.show:nth-of-type(11) {
  animation-delay: 5s;
}
.scroll-fadein-delay10.show:nth-of-type(12) {
  animation-delay: 5.5s;
}
.scroll-fadein-delay10.show:nth-of-type(13) {
  animation-delay: 6s;
}
.scroll-fadein-delay10.show:nth-of-type(14) {
  animation-delay: 6.5s;
}
.scroll-fadein-delay10.show:nth-of-type(15) {
  animation-delay: 7s;
}
.scroll-fadein-delay10.show:nth-of-type(16) {
  animation-delay: 7.5s;
}
.scroll-fadein-delay10.show:nth-of-type(17) {
  animation-delay: 8s;
}
.scroll-fadein-delay10.show:nth-of-type(18) {
  animation-delay: 8.5s;
}
.scroll-fadein-delay10.show:nth-of-type(19) {
  animation-delay: 9s;
}
.scroll-fadein-delay10.show:nth-of-type(20) {
  animation-delay: 9.5s;
}

/*右から左にフェイドイン*/
.scroll-fadein-right {
  opacity: 0;
}
.scroll-fadein-right.show {
  opacity: 1;
  animation: right-fadein 1s ease-out;
}

@keyframes right-fadein {
  from {
    opacity: 0;
    transform: translateX(40px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
/*左から右にフェイドイン*/
.scroll-fadein-left {
  opacity: 0;
}
.scroll-fadein-left.show {
  opacity: 1;
  animation: left-fadein 1s ease-out;
}

@keyframes left-fadein {
  from {
    opacity: 0;
    transform: translateX(-40px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
header {
  width: 100%;
  max-width: 1366px;
  z-index: 10;
  margin: auto;
  padding: 30px 60px;
}
header .nav-bar {
  width: 100%;
}
@media screen and (max-width: 768px) {
  header .nav-bar .global-menu {
    transition: all 0.3s ease-out;
    margin-top: inherit;
    position: fixed;
    height: 0;
    top: 0;
    left: 0;
    background-color: #ffffff;
    width: 100%;
    z-index: 105;
  }
}
header .nav-bar .global-menu .menu-list {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 40px;
}
@media screen and (max-width: 992px) {
  header .nav-bar .global-menu .menu-list {
    gap: 24px;
  }
}
@media screen and (max-width: 768px) {
  header .nav-bar .global-menu .menu-list {
    display: none !important;
  }
}
header .nav-bar .global-menu .menu-list li {
  margin-left: 14px;
}
header .nav-bar .global-menu .menu-list li a {
  display: block;
  position: relative;
}
header .nav-bar .global-menu .menu-list li a:before {
  position: absolute;
  content: "";
  width: 0;
  height: 2px;
  bottom: -4px;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.3s ease;
}
header .nav-bar .global-menu .menu-list li a:hover:before {
  width: 80%;
}
header .nav-bar .global-menu .menu-list li a p {
  text-align: center;
  color: #404140;
}
header .nav-bar .global-menu .menu-list li a p.en {
  font-size: 1rem;
}
header .nav-bar .global-menu .menu-list li a p.ja {
  margin-top: 6px;
  font-size: 0.625rem;
}
header .nav-bar .global-menu.show {
  height: 100vh;
  overflow: scroll;
}
header .nav-bar .global-menu.show .menu-list {
  width: 100%;
  position: absolute;
  z-index: 110;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex !important;
  opacity: 0;
  animation: menuFadein 0.5s forwards;
  animation-delay: 0.5s;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 15%;
}
header .nav-bar .global-menu.show .menu-list li {
  margin: 0 auto 10px;
}
header .nav-bar .global-menu.show .menu-list li a {
  border: none;
  color: #404140;
  font-size: 1.5rem;
  position: relative;
}
header .nav-bar .global-menu.show .menu-list li a:before {
  position: absolute;
  content: "";
  width: 0;
  height: 2px;
  bottom: -4px;
  left: 50%;
  background-color: #ffffff;
  transform: translateX(-50%);
  transition: all 0.3s ease;
}
header .nav-bar .global-menu.show .menu-list li a:hover:before {
  width: 80%;
}

@keyframes menuFadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.toggle-hamburger {
  display: none;
}
@media screen and (max-width: 768px) {
  .toggle-hamburger {
    display: block;
    cursor: pointer;
    width: 65px;
    height: 65px;
    padding: 16px 15px;
    position: fixed;
    top: 0;
    right: 0px;
    z-index: 120;
  }
}
@media screen and (max-width: 768px) and (max-width: 992px) {
  .toggle-hamburger {
    right: 0px;
  }
}
@media screen and (max-width: 768px) {
  .toggle-hamburger span {
    position: relative;
    display: block;
  }
  .toggle-hamburger span::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 2px;
    background-color: #ffffff;
    top: 5px;
    left: 0;
    transform: translateY(0) rotate(0);
    transition: all 0.3s;
  }
  .toggle-hamburger span:nth-of-type(2)::before {
    transform: translateY(10px);
    width: 30px;
  }
  .toggle-hamburger span:nth-of-type(3)::before {
    transform: translateY(20px);
    width: 20px;
    opacity: 1;
  }
}
@media screen and (max-width: 768px) {
  .toggle-hamburger p {
    font-size: 0.75rem;
    letter-spacing: 0;
    text-align: center;
    color: #ffffff;
    transform: translateY(20px);
  }
}
@media screen and (max-width: 768px) {
  .toggle-hamburger.close span:before {
    background-color: #ffffff;
  }
  .toggle-hamburger.close span:nth-of-type(1)::before {
    transform: rotate(-45deg);
  }
  .toggle-hamburger.close span:nth-of-type(2)::before {
    width: 100%;
    transform: rotate(45deg) translateY(0);
  }
  .toggle-hamburger.close span:nth-of-type(3)::before {
    opacity: 0;
  }
}

.wrap {
  padding: 16px;
}

#slide_wrap {
  position: relative;
  overflow: hidden;
}
#slide_wrap .slide_item {
  opacity: 0;
  transform: scale(1);
  transition: opacity 2s linear, transform 7.5s linear;
  position: relative;
}
#slide_wrap .slide_item:not(:first-child) {
  position: absolute;
  top: 0;
  left: 0;
}
#slide_wrap .slide_item.show_ {
  opacity: 1;
}
#slide_wrap .slide_item.zoom_ {
  transform: scale(1.1);
}
#slide_wrap .slide_item img {
  display: block;
}

/*---------------------*/