.pagination{
  margin-top: 30px;
  max-width: 570px;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  @extend .noto-serif;
  @include mediaS{
    margin-top: 24px;
  }
  a{
    color: $black;
    // padding: 2px;
    transition: transform .7s;
    height: 25px;
    width: 25px;
    font-weight: 700;
    background: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover{
      background-color: $black;
      color: $white;
      // text-align: center;
      // padding: 2px 5px 4px;
    }
    &.prev{
      background: inherit;
      // margin-right: 10px;
      position: relative;
      &:after{
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        background-image: url('../img/news/prev-arrow.svg');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        width: 7px;
        height: 10px;
      }
    }
    &.next{
      background: inherit;
      margin-left: 10px;
      position: relative;
      &:after{
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        background-image: url('../img/news/next-arrow.svg');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        width: 7px;
        height: 10px;
      }
    }
    &.no-left{
      margin-left: 0px;
    }
  }
  span{
    &.current{
      background-color: $black;
      color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 25px;
      width: 25px;
    }
    &.first{
      margin-left: 0px;
    }
  }
  .pager{
    margin-left: 10px;
  }
}