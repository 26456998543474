.menu-right-space{
  @include mediaM{
    margin-right: 4%;
  }
}
.menu-center-line{
  margin-top: -32px;
  width: 100%;
  padding-top: 50%;
  clip-path: polygon(0 1%, 100% 30%, 100% 99%, 0 70%);
  position: relative;
  @include mediaML{
    margin-top: 24px;
  }
  @include mediaXS{
    padding-top: 70%;
  }
  img{
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
  }
}
.menu{
  &__content{
    .left-space{
      margin-left: 80px;
      @include mediaM{
        margin-left: 4%;
      }
      &.none{
        @include mediaM{
          margin-left: 0;
        }
      }
    }
    position: relative;
    &.lunch{
      margin-top: 178px;
      @include mediaM{
        margin-top: 18%;
      }
    }
    &.mealSet{
      margin-top: 438px;
      @include mediaM{
        margin-top: 40%;
      }
    }
    &.oneDish{
      margin-top: -40px;
      @include mediaM{
        // margin-top: 40%;
      }
    }
    &.drink{
      margin-top: 220px;
      @include mediaM{
        margin-top: 22%;
      }
    }
    &__title{
      font-size: 2rem;
      font-weight: 400;
      @include mediaM{
        font-size: 1.5rem;
      }
      @include mediaXS{
        font-size: 1.25rem;
      }
      @extend .menu-right-space;
    }
    &__detail{
      margin-top:20px;
      font-size: 1.125rem;
      @extend .menu-right-space;
      @extend .noto-serif;
      @include mediaM{
        font-size: 1rem;
        margin-top:16px;
      }
      @include mediaXS{
        font-size: 0.875rem;
      }
    }
    &__image-top{
      margin-top: 150px;
      width: 45.6%;
      @include mediaM{
        width: 70%;
        margin-top: 15%;
      }
      img{
        display: block;
        width: 100%;
      }
    }
    &__image-detail{
      font-size: 1rem;
      font-weight: 700;
      @extend .noto-serif;
      margin-top: 24px;
      @include mediaM{
        font-size: 0.875rem;
        margin-top: 16px;
      }
      span{
        margin-left: 24px;
        display: inline-block;
        @include mediaS{
          margin-left: 12px;
        }
      }
    }
    &__flex{
      margin-top: 100px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      @extend .menu-right-space;
      @include mediaM{
        align-items: flex-start;
        margin-top: 10%;
        flex-direction: column-reverse;
      }
      &.set-flex{
        align-items: center;
      }
      &.dish-flex{
        margin-right: 80px;
        @include mediaM{
          margin-right: 4%;
        }
      }
      &__list{
        width: 43.6%;
        @include mediaM{
          width: 100%;
          margin-top: 48px;
        }
        @include mediaXS{
          margin-top: 24px;
        }
        &.lunch-list{
          margin-left: 80px;
          @include mediaM{
            margin-left: 0;
          }
          
        }
        &__item{
          padding-bottom: 8px;
          border-bottom: 1px solid #707070;
          display: flex;
          align-items: flex-end;
          padding-left: 8px;
          padding-right: 8px;
          &:nth-child(n + 2){
            margin-top: 30px;
            @include mediaM{
              margin-top:20px;
            }
          }
          @include mediaXS{
            flex-direction: column;
            padding-left: 4px;
            padding-right: 4px;
          }
          p{
            font-size: 1rem;
            @extend .noto-serif;
            @include mediaM{
              font-size: 0.875rem;
            }
          }
          .name{
            width: 100%;
          }
          .price{
            flex-shrink: 0;
            width: 180px;
            text-align: right;
            @include mediaXS{
              width: 100%;
              margin-top: 6px;
            }
            &.long{
              width: 200px;
              @include mediaXS{
                width: 100%;
              }
            }
          }
        }
      }
      &__image{
        width: 41.5%;
        @include mediaM{
          width: 91%;
        }
        &.mealSet-image{
          width: 49.9%;
          @include mediaM{
            width: 91%;
          }
        }
        &.drink-image{
          width: 50%;
          @include mediaM{
            width: 100%;
          }
          .first{
            width: 42.2%;
          }
          .second{
            width: 52.1%;
            margin-left: auto;
            // margin-top: -132px;
            // @include mediaM{
            //   margin-top: -13%;
            // }
          }
          .third{
            width: 52.1%;
            margin-top: 26px;
            margin-left: 16px;
          }
        }
        img{
          display: block;
          width: 100%;
        }
      }
      &.drink-flex{
        @include mediaM{
          margin-right: 0;
        }
        .menu__content__flex__list{
          margin-top: 46px;
          .menu__content__flex__list__item{
            .name{
              span{
                display: inline-block;
              }
            }
            .price{
              width: 210px;
              padding-left: 10px;
              text-align: left;
              @include mediaM{
                width: 185px;
              }
              @include mediaXS{
                width: 100%;
                text-align: right;
              }
            }
          }
        }
      }
    }
    &__bottom-flex{
      margin-top: 40px;
      margin-right: 80px;
      width: calc(100% - 80px);
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      @include mediaM{
        align-items: flex-start;
        flex-direction: column;
        width: 92%;
        margin: 32px 4% 0;
      }
      &__list{
        width: 43.6%;
        @include mediaM{
          width: 100%;
          margin-top: 48px;
        }
        @include mediaXS{
          margin-top: 24px;
        }
        &__item{
          padding-bottom: 8px;
          border-bottom: 1px solid #707070;
          display: flex;
          align-items: flex-end;
          padding-left: 8px;
          padding-right: 8px;
          &:nth-child(n + 2){
            margin-top: 30px;
            @include mediaM{
              margin-top:20px;
            }
          }
          @include mediaXS{
            flex-direction: column;
            padding-left: 4px;
            padding-right: 4px;
          }
          p{
            font-size: 1rem;
            @extend .noto-serif;
            @include mediaM{
              font-size: 0.875rem;
            }
          }
          .name{
            width: 100%;
          }
          .price{
            flex-shrink: 0;
            width: 180px;
            text-align: right;
            @include mediaXS{
              width: 100%;
              margin-top: 6px;
            }
            &.other{
              width: 315px;
              @include mediaXS{
                width: 100%;
              }
            }
          }
        }
      }
      &__image{
        width: 46.8%;
        margin-top: 64px;
        @include mediaM{
          width: 91%;
          margin-top: 32px;
        }
        img{
          display: block;
          width: 100%;
        }
      }
      img{
        display: block;
        width: 100%;
      }
    }
    .lunch-line{
      bottom: -300px;
      position: absolute;
      z-index: -1;
      background-image: url('../img/menu/menu-back.png');
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      left: 0;
      width: 100%;
      padding-top: 70%;
      clip-path: polygon(0 50%, 100% 3%, 100% 50%, 0 98%);
      @include mediaXL{
        bottom: -200px;
      }
      // @include mediaL{
      //   bottom: -180px;
      // }
      @include mediaM{
        bottom: -7%;
      }
      @include mediaXS{
        padding-top: 100%;
        clip-path: polygon(0 35%, 100% 15%, 100% 70%, 0 98%);
      }
    }
    &__block{
      position: relative;
      padding-top: 140px;
      @include mediaM{
        padding-top: 0;
        margin-top:14%;
      }
      &__image{
        width: 66%;
        @include mediaM{
          width: 91%;
          margin-top: 14%;
        }
        &.left{
          margin-right: auto;
        }
        &.right{
          margin-left: auto;
        }
        &.second{
          margin-top: 8px;
          width: 47.8%;
          @include mediaM{
            width: 70.9%;
            margin-top: 24px;
          }
        }
        &.third{
          margin-top: 64px;
          width: 47.8%;
          @include mediaM{
            width: 70.9%;
            margin-top: 32px;
          }
        }
      }
      
      img{
        display: block;
        width: 100%;
      }
      &__line{
        position: absolute;
        bottom: -250px;
        left: 0;
        z-index: -1;
        background-image: url('../img/menu/menu-back.png');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        width: 100%;
        padding-top: 70%;
        clip-path: polygon(0 35%, 100% 1%, 100% 65%, 0 99%);
        @include mediaM{
          padding-top: 80%;
          bottom: -20%;
        }
        @include mediaXS{
          padding-top: 100%;
        }
        // img{
        //   position: absolute;
        //   max-width: 100%;
        //   max-height: 100%;
        //   width: 100%;
        //   height: 100%;
        //   object-fit: cover;
        // }
      }
    }
  }
}
.menu-take{
  margin-top: 230px;
  position: relative;
  padding: 168px 80px;
  @include mediaM{
    margin-top: 23%;
    padding: 16% 4%;
  }
  &__title{
    font-size: 2rem;
    text-align: center;
    @include mediaM{
      font-size: 1.5rem;
    }
    @include mediaXS{
      font-size: 1.25rem;
    }
  }
  &__flex{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 80px auto 0;
    @include mediaM{
      flex-direction: column;
      max-width: 600px;
      margin: 64px auto 0;
    }
    @include mediaXS{
      margin: 40px auto 0;
    }
    &__content{
      width: calc(50% - 52px);
      @include mediaM{
        
        width: 100%;
      }
      &.space{
        @include mediaM{
          margin-top: 48px;
        }
      }
      img{
        display: block;
        width: 100%;
      }
    }
    &__title{
      font-size: 1.375rem;
      margin-top: 24px;
      text-align: center;
      font-weight: 700;
      @extend .noto-serif;
      @include mediaM{
        font-size: 1.125rem;
      }
      @include mediaXS{
        font-size: 1rem;
        margin-top: 16px;
      }
    }
  }
  &:after{
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../img/menu/take-back.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    @include mediaM{
      background-image: url('../img/menu/take-back-sp.png');
    }
  }
}