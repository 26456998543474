$darkBlack: #1F1717;
$black: #2E291A;

$purple: #DEC6DD;
$orange: #FF6600;
$gray: #FAF1E5;
$gold: #B49E43;


/* グラデーション */
$backgroundFooter: linear-gradient(to right, #ffffff 0%, #fafafa 15%, #81B8EA 30%, #5B9ED8 50%, #5B9ED8 100%);

$white: #ffffff;

$default: 'Yuji Syuku', serif,
apple-system, blinkMacSystemFont, /* Macの欧文はSan Francisco (OS X 10.11以降) */
'Helvetica Neue', /* Macの欧文 (OS X 10.10以前) */
'Segoe UI',       /* Windowsの欧文 */
YuGothicM,        /* Windows8.1以上は游ゴシック */
YuGothic,         /* Macの游ゴシック */
Meiryo,           /* 游ゴシックが入っていないWindows */
Arial,
Helvetica,
"Yu Gothic Medium",
"ヒラギノ角ゴ Pro W3", sans-serif;

$notoSerif: 'Noto Serif JP', serif;
$notoSans: 'Noto Sans JP', sans-serif;


@mixin mediaXL($width-media: 1480px) {
  @media screen and (max-width: $width-media) {
    @content;
  }
}
@mixin mediaLL($width-media: 1366px) {
  @media screen and (max-width: $width-media) {
    @content;
  }
}
@mixin mediaL($width-media: 1280px) {
  @media screen and (max-width: $width-media) {
    @content;
  }
}
@mixin mediaGrid($width-media: 1150px) {
  @media screen and (max-width: $width-media) {
    @content;
  }
}
@mixin mediaML($width-media: 1100px) {
  @media screen and (max-width: $width-media) {
    @content;
  }
}
@mixin mediaM($width-media: 992px) {
  @media screen and (max-width: $width-media) {
    @content;
  }
}
@mixin mediaS($width-media: 768px) {
  @media screen and (max-width: $width-media) {
    @content;
  }
}
@mixin mediaXS($width-media: 576px) {
  @media screen and (max-width: $width-media) {
    @content;
  }
}
@mixin mediaPHONE($width-media: 450px) {
  @media screen and (max-width: $width-media) {
    @content;
  }
}
@mixin safari {
  @at-root _::-webkit-full-page-media, _:future, :root & {
    @content;
  }
}