.pageTop{
  opacity: 0;
  transition: opacity 0.3s ease;
  position: fixed;
  z-index: 20;
  width: 60px;
  height: 60px;
  bottom: 42px;
  right: 80px;
  background: $black;
  box-shadow: 3px 3px 3px rgba($color: #000000, $alpha: .16);
  @include mediaM{
    right: 24px;
    width: 42px;
    height: 42px;
  }
  @include mediaXS{
    right: 16px;
  }
  &__inner{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    @include mediaS{
    }
    img{
      width: 21px;
      display: block;
      @include mediaS{
        width: 16px;
      }
    }
  }
}
#pageTop.show {
  opacity: 1;
}
#pageTop.fixed {
  position: absolute;
}