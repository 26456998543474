//  下層ページ
.header{
  position: absolute;
  z-index: 5;
  top: 240px;
  left: 0px;
  width: 100%;
  // height: 1560px;
  @include mediaLL{
    top: 140px;
  }
  @include mediaML{
    top: 200px;
  }
  @include mediaM{
    position: relative;
    top: inherit;
    left: inherit;
    height: 80px;
    z-index: 150;
  }
  @include mediaXS{
    height: 64px;
  }
  &.news-header{
    display: none;
    @include mediaM{
      display: block;
    }
  }
  .nav-bar{
    width: 100%;
    height: 100%;
    position: relative;
    padding: 220px 80px 0;
    @include mediaML{
      padding: 200px 80px 0;
    }
    @include mediaM{
      position: fixed;
      background: $white;
      height: 80px;
      top: 0;
      left: 0;
      padding: 0 4%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include mediaXS{
        height: 64px;
      }
    }
    &:after{
      content: '';
      position: absolute;
      z-index: -1;
      left: 0;
      top: 0;
      clip-path: polygon(0 39%, 0 0, 100% 64%, 100% 100%);
      background-image: url('../img/common/header-back.png');
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      width: 100%;
      height: 1260px;
      @include mediaLL{
        height: auto;
        padding-top: 100%;
      }
      @include mediaM{
        display: none;
      }
    }
    .logo{
      width: 160px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-left: 40px;
      @include mediaM{
        margin-left: 0;
      }
      @include mediaXS{
        width: 95px;
      }
      a{
        width: 100%;
        display: flex;
        align-items: center;
        transition:0.5s;
        color: $black;
        img{
          display: block;
          width: 40px;
          object-fit: cover;
          flex-shrink: 0;
          @include mediaXS{
            width: 24px;
          }
        }
        .text{
          margin-left: 10px;
          font-size: 1.75rem;
          white-space: nowrap;
          line-height: 1;
          @include mediaS{
            font-size: 1.5rem;
          }
          @include mediaXS{
            margin-left: 7px;
            font-size: 1rem;
          }
        }
        &:hover{
          opacity: 0.7;
        }
      }
    }
    .global-menu {
      width: 100%;
      margin-top: 30px;
      @include mediaM{
        transition: all .3s ease-out;
        margin-top: inherit;
        position: fixed;
        height: 0;
        top: 0;
        left: 0;
        background-image: url('../img/common/header-back.png');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        width: 100%;
        z-index: 105;

      }
      .menu-list {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        // flex-direction: row-reverse;
        width: 100%;
        height: 100%;
        @include mediaM{
          display: none!important;
        }
        li{
          &:nth-of-type(n+2){
            margin-left: 24px;
            @include mediaL{
              margin-left: 16px;
            }
            @include mediaM{
              margin-left: 0;
              
            }
          }
          @include safari{
            width: 54px;
            @include mediaXS{
              width: 51px;
            }
          }
          .link{
            white-space: nowrap;
            display: block;
            padding: 8px 12px 16px;
            background: $white;
            position: relative;
            color: $black;
            font-size: 1.25rem;
            text-align: center;
            letter-spacing: 6px;
            writing-mode: vertical-rl;
            transition: all .5s cubic-bezier(.215,.61,.355,1);
            @include mediaML{
              font-size: 1rem;
            }
            @include mediaM{
              font-size: 1.25rem;
            }
            @include mediaXS{
              font-size: 1.125rem;
            }
            @include safari{
              padding: 8px 12px 40px;
            }
            &.active{
              background: $darkBlack;
              color: $white;
              &:hover{
                background: $darkBlack;
                color: $white;
              }
            }
            &:hover{
              background: $black;
              color: $white;
            }
          }
        }
      }
      //グローバルナビの表示非表示
      &.show {
        height: 100vh;
        overflow: scroll;
        .menu-list {
          width: 100%;
          position: absolute;
          z-index: 110;
          top: 0;
          left: 50%;
          transform: translate(-50%, 0);
          display: flex !important;
          opacity: 0;
          animation: menuFadein .5s forwards;
          animation-delay: .5s;
          padding-top: 18%;
          justify-content: center;
          padding-left: 4%;
          padding-right: 4%;
          @include mediaXS{
            padding-top: 22%;
          }
          li {
            display: inline-block;
            margin-right: 20px;
            @include mediaXS{
              margin-right: 12px;
            }
            &:last-child{
              margin-right: 0;
            }
            .link {
            }
          }
        }
      }
    }
  }
}
//グローバルメニュー表示のアニメーション
@keyframes menuFadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.toggle-hamburger {
  display: none;
  @include mediaM{
    display: block;
    cursor: pointer;
    width: 40px;
    height: 35px;
    padding: 5px;
    z-index: 120;
  }
  span {
    position: relative;
    display: block;
    &::before {
      position: absolute;
      content: "";
      width: 100%;
      height: 2px;
      background-color: $black;
      top: 0px;
      left: 0;
      transform: translateY(0) rotate(0);
      transition: all .3s;
    }
    &:nth-of-type(2) {
      &::before {
        transform: translateY(10px);
        width: 100%;
      }
    }
    &:nth-of-type(3) {
      &::before {
        transform: translateY(20px);
        width: 100%;
        opacity: 1;
      }
    }
  }
  &.close {
    span {
      &:before {
        top: 7px;
      }
      &:nth-of-type(1) {
        &::before {
          transform: rotate(-45deg);
        }
      }

      &:nth-of-type(2) {
        &::before {
          width: 100%;
          transform: rotate(45deg) translateY(0);
        }
      }
      &:nth-of-type(3) {
        &::before {
          opacity: 0;
        }
      }
    }
  }
}

.menu {}
.menu-con {}
.menu-con p:before {content: ""; display: block;position:absolute;top:0;left:0;z-index:1;width:100%;height: 100%;opacity: 1;background: rgba(0,0,0,0);-webkit-transition: all 0.5s;transition: all 0.5s;}
.menu-con:hover p:before {background: rgba(0,0,0,0.2)}
.menu-con p {height:20px;-webkit-align-self:center;color:white;font-size:25px;z-index:2;cursor:pointer}
@media screen and (max-width: 600px) {.menu-con {min-width:50%}}
@media screen and (max-width: 350px) {.menu-con {min-width:100%}}