.regular-btn{
  margin-top: 40px;
  max-width: 260px;
  width: 85%;
  @include mediaXS{
    margin-top: 24px;
    max-width: 130px;
  }
  &.left{
    margin-left: auto;
  }
  &.right-space{
    margin-right: 80px;
    @include mediaM{
      margin-right: 4%;
    }
  }
  &.right{
    margin-right: auto;
  }
  &.left-space{
    margin-left: 80px;
    @include mediaM{
      margin-left: 4%;
    }
  }
  &.about{
    @include mediaXS{
      max-width: 200px;
      margin-top: 40px;
    }
  }
  &__link{
    display: block;
    width: 100%;
    background: $white;
    padding: 14px 24px 14px 40px;
    color: $black;
    font-size: 1rem;
    position: relative;
    @include mediaXS{
      padding: 8px 24px 8px 8px;
      font-size: 0.75rem;
    }
    &:after{
      content: '';
        display: block;
        position: absolute;
        top: calc(55% - 5px);
        right: 30px;
        width: 20px;
        height: 5px;
        border: none;
        border-right: 2px solid $black;
        border-bottom: 1px solid $black;
        transform: skew(45deg);
        transition: all 0.3s ease;
        @include mediaXS{
          top: calc(55% - 4px);
          width: 15px;
          height: 4px;
          right: 16px;
        }
    }
    &:hover{
      &:after{
        right: 20px;
        width: 30px;
        @include mediaXS{
          width: 25px;
          right: 6px;
        }
      }
    }
    &.black{
      background: $darkBlack;
      color: $white;
      &:after{
        right: 20px;
        border: none;
        border-right: 2px solid $white;
        border-bottom: 1px solid $white;
      }
      &:hover{
        &:after{
          right: 10px;
        }
      }
    }
    &.pd-left{
      padding-left: 16px;
    }
  }
}
.top-btn{
  max-width: 260px;
  height: 50px;
  width: 85%;
  margin-top: 200px;
  background: $black;
  color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  @include mediaM{
    margin-top: 20%;
    font-size: 0.875rem;
    height: 42px;
    max-width: 220px;
  }
  span{
    display: block;
    width: 80%;
    position: relative;
    &:before{
      content: '';
      display: block;
      position: absolute;
      top: calc(60% - 5px);
      right: 0px;
      width: 20px;
      height: 5px;
      border: none;
      border-right: 2px solid $white;
      border-bottom: 1px solid $white;
      transform: skew(45deg);
      transition: all 0.3s ease;
      @include mediaXS{
        top: calc(60% - 4px);
        width: 15px;
        height: 4px;
      }
    }
  }
  &:hover{
    span{
      &:before{
        right: -10px;
        width: 30px;
        @include mediaXS{
          width: 25px;
        }
      }
    }
  }
}
.top-news-btn{
  max-width: 260px;
  height: 50px;
  width: 85%;
  margin-top: 80px;
  background: $black;
  color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  @include mediaM{
    margin-top: 10%;
    font-size: 0.875rem;
    height: 42px;
    max-width: 220px;
  }
  span{
    display: block;
    width: 60%;
    position: relative;
    &:before{
      content: '';
      display: block;
      position: absolute;
      top: calc(60% - 5px);
      right: 0px;
      width: 20px;
      height: 5px;
      border: none;
      border-right: 2px solid $white;
      border-bottom: 1px solid $white;
      transform: skew(45deg);
      transition: all 0.3s ease;
      @include mediaXS{
        top: calc(60% - 4px);
        width: 15px;
        height: 4px;
      }
    }
  }
  &:hover{
    span{
      &:before{
        right: -10px;
        width: 30px;
        @include mediaXS{
          width: 25px;
        }
      }
    }
  }
}
.news-display-btn{
  max-width: 260px;
  height: 50px;
  width: 85%;
  margin-top: 200px;
  margin-left: auto;
  margin-right: auto;
  background: $black;
  color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  @include mediaM{
    margin-top: 20%;
    font-size: 0.875rem;
    height: 42px;
    max-width: 220px;
  }
  span{
    display: block;
    width: 85%;
    position: relative;
    &:before{
      content: '';
      display: block;
      position: absolute;
      top: calc(60% - 5px);
      right: 0px;
      width: 20px;
      height: 5px;
      border: none;
      border-right: 2px solid $white;
      border-bottom: 1px solid $white;
      transform: skew(45deg);
      transition: all 0.3s ease;
      @include mediaXS{
        top: calc(60% - 4px);
        width: 15px;
        height: 4px;
      }
    }
  }
  &:hover{
    span{
      &:before{
        right: -10px;
        width: 30px;
        @include mediaXS{
          width: 25px;
        }
      }
    }
  }
}
.insta-btn{
  display: block;
  margin-top: 238px;
  margin-left: auto;
  margin-right: auto;
  max-width: 330px;
  width: 85%;
  overflow: hidden;
  @include mediaM{
    margin-top: 21%;
  }
  @include mediaXS{
    max-width: 240px;
  }
  img{
    transition: .3s;
    width: 100%;
    display: block;
  }
  &:hover{
    img{
      transform: scale(1.05);
    }
  }
}
.tel-btn{
  margin-top: 114px;
  margin-left: auto;
  margin-right: auto;
  max-width: 330px;
  width: 85%;
  overflow: hidden;
  
  &.pc{
    display: block;
    @include mediaM{
      display: none;
    }
  }
  &.sp{
    display: none;
    @include mediaM{
      display: block;
    }
  }
  @include mediaM{
    margin-top: 18%;
    // padding-top: 12%;
  }
  @include mediaXS{
    margin-top: 26%;
  }
  @include mediaXS{
    max-width: 240px;
  }
  img{
    width: 100%;
    display: block;
    transition: .3s;
    filter: grayscale(40%);
  }
  &:hover{
    img{
      transform: scale(1.05);
    }
  }
}