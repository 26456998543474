.sliderList {
  position: relative;
  overflow: hidden;
  .sliderItem {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition:all 4s ease-in-out;
    // z-index: 0 !important;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      // mask-image: linear-gradient(rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 1) 50%);
      // mask-repeat: no-repeat;
      // mask-position: 0 100%;
      // mask-size: 100% 0;
      //transition: all 4s ease;
    }
    // 表示するSliderItemのCSS
    &.active {
      opacity: 1;
      .fvImage {
        animation: closeUpAnim 5s forwards;
      }
    }
  }
}
@keyframes closeUpAnim {
  0% {
    // mask-size: 100% 0;
    transform: scale(1, 1);
  }
  50% {

  }
  100% {
    // mask-size: 100% 250%;
    transform: scale(1.1, 1.1);
  }
}